@import "palettes";
@import "theme";
@import "mixins";

.table_wrapper {
  margin: 40px 0;
  @include trim-v-margins;
  overflow-x: auto;

  table {
    margin: 0 auto;
    border-collapse: collapse;

    th,
    td {
      padding: 10px 15px;
      border: solid 1px $gray-500;
    }
  }
}
